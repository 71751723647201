<template>
  <div class="user-page">
    <h1>Partner Login</h1>

    <form class="col-md-4 offset-md-4" @submit.prevent="doUserPartnerLogin">

      <div class="form-floating mb-3">
        <input
            v-model="login"
            :class="{'is-invalid': errors?.login}"
            type="text"
            class="form-control rounded-4"
            id="login-input"
            placeholder="Enter email as login" />
        <div class="invalid-feedback">{{ errors?.login }}</div>
        <label for="login-input">Login or email</label>
      </div>

      <div class="form-floating mb-3">
        <input
            v-model="password"
            :class="{'is-invalid': errors?.password}"
            type="password"
            class="form-control rounded-4"
            id="password-input"
            placeholder="Password" />
        <div class="invalid-feedback">{{ errors?.password }}</div>
        <label for="password-input">Password</label>
      </div>

      <button class="w-100 mb-2 btn btn-lg rounded-4 btn-primary" type="submit">Login</button>

    </form>

  </div>
</template>

<script>
import UserMixin from '@/modules/user/mixins/UserMixin';

export default {

  mixins: [UserMixin],

  data: () => ({
    login: null,
    password: null,
  }),

  mounted() {

    if (this.isUser())
      return this.redirect('/partner');
  },

  methods: {

    async doUserPartnerLogin() {

      let apiHolder = await this.sendApiPost('api/user/partner/login', {
        login: this.login,
        password: this.password
      });

      if (!apiHolder.isSuccess() || !apiHolder.data)
        return;

      const {accessToken, refreshToken} = apiHolder.data;
      this.saveUserAuth(accessToken, refreshToken);

      await this.redirect('/partner');
    }

  }

}
</script>

<style scoped>

</style>